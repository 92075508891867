<!-- NAME[epic=网销] 网销设置 -->
<template>
  <div style="padding: 20px">
    <el-form ref="form" :model="form">
      <h2>商城状态:</h2>
      <el-divider></el-divider>
      <el-form-item
        v-if="form.mall_falg == 1 && form.mall_status == 0"
        label=""
        prop=""
      >
        <label>
          <el-switch
            v-model="form.mall_falg"
            active-value="1"
            inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ccc"
            @change="mallChange1"
          ></el-switch>
          网销功能已开启，终端家数上线{{ form.mall_limit }}家
        </label>
        <div>
          <span>单笔订单起订金额：</span>
          <el-input v-model="form.order_min_amount" placeholder="请输入金额" style="width: 160px"></el-input>
        </div>
      </el-form-item>
      <el-form-item
        v-if="form.mall_falg == 1 && form.mall_status == 1"
        label=""
        prop=""
      >
        <label>
          <el-switch
            v-model="form.mall_status"
            active-value="0"
            inactive-value="1"
            active-color="#13ce66"
            inactive-color="#ccc"
            @change="mallChange"
          ></el-switch>
          已暂停使用 客户提示语:
          <el-input
            v-model="form.mall_msg"
            style="width: 220px"
            @blur="msgChange"
          ></el-input>
        </label>
      </el-form-item>
      <el-form-item v-if="form.mall_falg == 0" label="" prop="">
        <label>
          <el-switch
            v-model="form.mall_falg"
            disabled
            active-value="1"
            inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ccc"
          ></el-switch>
          网销功能未开启，请联系商单工作人员
        </label>

      </el-form-item>

      <h2>出货仓库:</h2>
      <el-divider></el-divider>
      <!-- <el-form-item label="1默认仓库:" prop="">
        <span>邀请客户开通网销的业务员所关联的实体仓库</span>
      </el-form-item> -->
      <el-form-item label="出货仓库:" prop="depot_id">
        <el-select v-model="form.depot_id">
          <el-option
            v-for="item in warehouse"
            :key="item.id"
            :label="item.depot_name"
            :value="item.id"
          ></el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="" prop="">
        <!-- is_stock -->
        <label>
          <el-switch
            v-model="form.is_stock"
            :active-value="1"
            :inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ccc"
          ></el-switch>
          网销下单仅可选购有库存的品牌及商品
        </label>
      </el-form-item>
      <h2>联系方式:</h2>
      <el-divider></el-divider>
      <el-radio-group v-model="form.connect_type">
        <el-form-item prop="">
          <el-radio label="1">业务员:客户将联系邀请其开通网销的业务员</el-radio>
        </el-form-item>
        <el-form-item prop="tel">
          <el-radio label="2">
            客服热线:
            <el-input
              v-model="form.tel"
              :disabled="form.connect_type != 2"
              style="width: 220px"
              placeholder="请输入客服热线"
              onkeyup="value=value.replace(/[^\d.]/g,'') "
            ></el-input>
          </el-radio>
        </el-form-item>
      </el-radio-group>
      <h2>退货设置:</h2>
      <el-divider></el-divider>
      <div>
        <label>
          <el-switch
            v-model="form.flag_return"
            active-value="1"
            inactive-value="0"
            active-color="#13ce66"
            inactive-color="#ccc"
          ></el-switch>
          默认关闭，开启后客户可在”我的“页面添加创建退货单
        </label>
      </div>
      <h2>营业资质</h2>
      <el-divider></el-divider>
      <div style="display: flex">
        <div>
          <uploadImage
            ref="uploadImage1"
            :limit="1"
            :max-size="3"
            :show-info="false"
            @uploadImg="changeImg1"
          ></uploadImage>
          <h3 class="center">营业执照</h3>
        </div>
        <div style="margin-left: 10px">
          <uploadImage
            ref="uploadImage2"
            :limit="1"
            :max-size="3"
            :show-info="false"
            @uploadImg="changeImg2"
          ></uploadImage>
          <h3 class="center">食品经营许可</h3>
        </div>
      </div>

      <div class="center">
        <el-button type="primary" @click="handlersave">保 存</el-button>
      </div>
    </el-form>
  </div>
</template>

<script>
  import { postAction } from '@/api/Employee'
  import uploadImage from '@/baseComponents/uploadImg2/uploadImg.vue'
  export default {
    components: { uploadImage },
    data() {
      return {
        form: {
          is_stock: '0',
          depot_id: '', //备选仓库id
          connect_type: '1', //联系方式1业务员，2客服热线
          tel: '', //热线电话
        },
        warehouse: [],
        url: {
          warehouse: '/baseAdmin/common/depot-list',
          save: '/shopAdmin/config/config-set',
        },
      }
    },
    mounted() {
      this.fetchData()
      this.handlerwarehouse()
    },
    methods: {
      changeImg1(v) {
        this.form.business_pic = v[0]
      },
      changeImg2(v) {
        this.form.food_pic = v[0]
      },
      // 详情
      async fetchData() {
        let { data } = await postAction('/shopAdmin/config/config')
        this.form = data
        // 营业执照
        this.form.depot_id = Number(this.form.depot_id)
        if (this.form.business_pic)
          this.$refs.uploadImage1.img = [this.form.business_pic]
        if (this.form.food_pic)
          this.$refs.uploadImage2.img = [this.form.food_pic]
      },
      mallChange1(v) {
        console.log('v', v)
        this.form.mall_status = 1
        this.handlersave()
      },
      mallChange(v) {
        console.log('v', v)
        // this.form.mall_status = 0
        this.handlersave()
      },
      msgChange(v) {
        // console.log('v', v)
        this.handlersave()
      },
      handlersave() {
        postAction(this.url.save, this.form)
          .then((res) => {
            console.log(res)
            if (res.code == 200) {
              this.$message({
                message: res.msg,
                type: 'success',
              })
              // this.$refs['form'].resetFields()
              this.fetchData()
            } else {
              this.$message({
                message: res.msg,
                type: 'error',
              })
            }
          })
          .catch((err) => {
            console.log(err)
          })
      },
      handlerwarehouse() {
        postAction(this.url.warehouse, { pageSize: -1 })
          .then((res) => {
            console.log(res)
            this.warehouse = res.data
          })
          .catch((err) => {
            console.log(err)
          })
      },
    },
  }
</script>

<style></style>
